"use strict";

;(function (root, $) {
    $(document).ready(function () {
        $(".js-dropdown").each(function (index, element) {
            var data, $parent, $button, $dropdownContent;

            $parent = $(element);
            $button = $parent.find(".js-dropdown-toggle-btn");
            $dropdownContent = $parent.find(".js-dropdown-content");

            data = {
                openClass: "open " + $dropdownContent.attr("data-className-open") || "open"
            };

            $button.click(function (event) {
                $dropdownContent.toggleClass(data.openClass);
                $parent.toggleClass("open");
                event.stopPropagation();
                return false;
            });

            $("body").click(function () {
                $dropdownContent.removeClass(data.openClass);
                $parent.removeClass("open");
            });
        });
    });
})(window, jQuery);